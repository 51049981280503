import { environment } from '../../environments/environment';
export const MENU = JSON.parse(environment.navBar.menuSetting);
export const NAV_ROLE_FILTER = JSON.parse(environment.navBar.roleFilter);
export const SIDEBAR = JSON.parse(environment.sideBar);
export const IMPORT = JSON.parse(environment.import);
export const UPLOAD_SIZE_CHART = JSON.parse(environment.uploadSizeChart);
export const AQL_PLAN = JSON.parse(environment.aqlPlan);
export const AQL_REPORT = JSON.parse(environment.aqlReport);

const defaultPage = MENU.filter((m:any) => { return m['default']; })[0]
const getDefaultSubPage = (menu:any) => {
  return menu.pages.length === 1 ? menu.pages[0] : menu.pages.find((m:any) => m.default);
}
export const DEFAULT_PAGE = Object.keys(defaultPage).includes('pages') ? getDefaultSubPage(defaultPage).name : defaultPage.name;

export const APIS = {
  LOGIN_V2: '/v2/user/ms-login',
  B2C_LOGIN: '/v2/user/b2c-login',
  LOGOUT: '/v1/user/logout',
  GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  GET_USERS: '/v1/user/list',
  UPDATE_USER: '/v1/user',
  POWERBI_TOKEN: '/v1/powerbi/token',
  CONSOLE_CENTERS: '/v1/consol-centres',
  UPLOADE_CSV_DATA: '/v1/shipment_booking_reports',
  CSV_DATA_LISTING: '/v1/shipment_booking_reports',
  UPDATE_INSPECTION_RECORD: '/v1/update_inspection_record',
  INSPECTION_LIST: '/v1/inspections',
  GET_COMPLINCE_CHECK_LIST: '/v1/complince_check_list',
  UPDATE_QC_DATA: '/v1/update_QC_record',
  ACCURACY_REPORT: '/v1/accuracy_report',
  COMPLIANCE_REPORT: '/v1/compliance_report',
  GARMENT_CHECK_REPORT: '/v1/garment_check_report',
  DOWNLOAD_REPORTS: '/v1/downloade_reports',
  GET_SIZE_CHART_INFO: '/v1/size_chart',
  GET_SIZE__CHART_LIST: '/v1/style_code/list',
  UPLOAD_SIZE_CHART: '/v1/size_chart',
  ADD_STYLE_CODE: '/v1/style_code',
  INSPECTION_DETAILS: '/v1/inspection_detail',
  INSPECTION_PDF: '/v1/inspection_pdf',
  INSPECTION_SUMMARY_PDF: '/v1/inspection_summary_pdf',
  UPDATE_AQL_INSPECTION: '/v1/aql_result',
  FETCH_INSPECTION_SAMPLES: '/v1/AQL_report_info',
  COMPLIANCE_DETAILS: '/v1/compliance_report_detail',
  UPDATE_COMPLIANCE: '/v1/Compliance_Report',
  ADD_USER: '/v1/admin/addUser',
  SELECT_UNSELECT_COMPLIANCE: '/v1/compliance/selectUnselect',
  SELECT_UNSELECT_AQL: '/v1/Aql/selectUnselect',
  V2_SYNC: '/v2/inspection/sync',
  INSPECTION_TYPES: '/v1/inspection/types',
  SKIP_REASONS: '/v1/inspection/skip-reasons',
  INSPECTION_LEVELS: '/v1/inspection/levels',
  CLIENT_DECISIONS: '/v1/inspection/client-decisions',
  SUPPLIER_LIST: '/v1/suppliers',
  DELETE_DEFECT: '/v1/defect',
  DELETE_INSPECTION: '/v1/inspection',
  INSPECTION_VERSION: '/v2/inspections/{0}/versions',
  RESET: '/v1/admin/reset',
  FETCH_USER_ROLES: '/v2/roles',
  GET_STATUS_LIST: '/v1/inspection-statuses',
  GET_RESULT_LIST: '/v1/inspection-results',
  GET_DEPARTMENT_LIST: '/v1/departments',
  PO_GENERATE_INSPECTIONS: '/v2/po/inspections',
  PO_UPDATE_DATA: '/v2/po/{0}',
  PO_GET_DATA: '/v2/po',
  GET_OR_UPDATE_SKIPPED_INSPECTIONS: '/v2/inspection/skipped',

};

export const USER_CONSTANTS = {
  PLATFORM: 2,
  ROLE: 5,
};

export const USER_DETAILS = {
  TOKEN: 'token',
  USER_ID: '_id',
  APPROVED: 'isApproved',
  ROLE: 'role',
  USER_DATA: 'userData',
  LOCATION: 'location',
  ID: 'id',
  Email: 'email',
  TRY_LOGIN:'tryLogin'
};

export const TABLE_DATA = {
  USERS: [
    // { name: 'Sr No.', case: 'index' },
    { name: 'NAME', key: 'name', case: 'normal', sort: true },
    { name: 'EMAIL', key: 'email', case: 'normal', sort: true },
    { name: 'ROLE', key: 'role_id', case: 'role', values: ['Administrator', 'Data', 'Compliance', 'AQL', 'Pending', 'Revoked', 'Client'] },
    { name: 'LOCATION', key: 'primary_location', case: 'location' },
    { name: 'PLATFORM', case: 'platform', key: 'platform', values: ['Tablet', 'Web', 'Both'] },
    { name: 'APPROVAL', key: 'approval', case: 'approval' },
    // { name: 'CREATED DATE', key: 'createdAt', case: 'date' },
    // { name: '', case: 'approveButton' },
    { name: 'ACTION', case: 'user-action' }
  ],
  COMPLIANCE_PAN: [
    { name: 'COMP', key: 'compdata_select_for_compliance', case: 'select_unselect' },
    { name: 'Insp. Date', key: 'inspection_date', case: 'name' },
    { name: 'Loc.', type: 'number', key: 'UDC_id', case: 'normal' },
    { name: 'Insp.', key: 'inspection_number', case: 'normal' },
    { name: 'Supplier Name', key: 'supplier_name', case: 'name' },
    { name: 'Season', key: 'season_code', case: 'normal' },
    { name: 'PO Numbers', key: 'client_po', case: 'normal' },
    { name: 'Style', key: 'style_code', case: 'normal' },
    // { name: 'Description', key: 'description', case: 'normal' },
    { name: 'Colour', key: 'colour', case: 'normal' },
    { name: 'Booking Ref.', key: 'booking_reference', case: 'normal' },
    { name: 'Result', key: 'compliance_result', case: 'result' },
    { name: 'Carton Qty', type: 'number', key: 'carton_count', case: 'normal' },
    { name: 'Paper Tape', key: 'paper_tape', case: 'paper_tape_check' },
    { name: 'Total Units', type: 'number', key: 'actual_received_qty', case: 'normal' },
    { name: 'PO Qty', type: 'number', key: 'po_quantity', case: 'normal' },
    { name: 'Current Status', key: 'status_EN', case: 'normal' },
    { name: 'Inspector Name', key: 'Inspector', case: 'normal' },
    { name: 'Arrived', key: 'arrived_consol_date', case: 'normal' },
  ],
  COMPLIANCE_REPORT: [
    { name: 'Consol.', key: 'inspection_date', case: 'name' },
    { name: 'PO', key: 'UDC_id', case: 'normal' },
    { name: 'Line', key: 'inspection_number', case: 'normal' },
    { name: 'Date', key: 'supplier_name', case: 'normal' },
    { name: 'Supplier Name', key: 'season_code', case: 'name' },
    { name: 'Supplier Code', key: 'client_pos', case: 'normal' },
    { name: 'Total Ctns', type: 'number', key: 'style_code', case: 'normal' },
    { name: 'Description', key: 'style_name', case: 'normal' },
    { name: 'Total Qty.', type: 'number', key: 'colours', case: 'normal' },
    { name: 'PO Qty.', type: 'number', key: 'po_quantity', case: 'normal' },
  ],
  ACCURACY_REPORT: [
    // { name: 'Consol', key: 'UDC_id', case: 'normal' },
    { name: 'Client PO', key: 'client_po', case: 'normal' },
    { name: 'Consol. Centre', key: 'Consol_Centre', case: 'normal' },
    { name: 'PO', key: 'PO', case: 'normal' },
    { name: 'PO Line', key: 'PO_Line', case: 'normal' },
    { name: 'Date', key: 'Date', case: 'normal' },
    { name: 'Supplier Name', key: 'supplier_name', case: 'normal' },
    { name: 'Supplier Code', key: 'supplier_code', case: 'normal' },
    { name: 'Total Ctns', type: 'number', key: 'Total_Cartons', case: 'normal' },
    { name: 'Total Qty.', type: 'number', key: 'Total_Quantity', case: 'normal' },
    { name: 'Paper Tape', key: 'paper_tape', case: 'paper_tape_check' },
    { name: 'Carton No', type: 'number', key: 'Carton_Number', case: 'normal' },
    { name: 'Qty:Packing List', type: 'number', key: 'Qty_PackingList', case: 'normal' },
    { name: 'Qty: Carton Label', type: 'number', key: 'Qty_CartonLabel', case: 'normal' },
    { name: 'Qty: Unit Count', type: 'number', key: 'Qty_UnitCount', case: 'normal' },
    { name: 'PO Checked(%)', type: 'number', key: 'PercentagePOChecked', case: 'normal' },
    { name: 'Comments', key: 'Comments', case: 'normal' },
    { name: 'Rejected', key: 'Rejected', case: 'normal' },
  ],
  COMPLIANCE: [
    // { name: 'Consol', key: 'UDC_id', case: 'normal' },
    { name: 'Consol. Centre', key: 'Consol_Centre', case: 'normal' },
    // { name: 'Image Ref.', key: 'Image_Reference', case: 'link' },
    { name: 'Date', key: 'Date', case: 'normal', width: '50px' },
    { name: 'Supplier Name', key: 'supplier_name', case: 'normal' },
    { name: 'Supplier Code', key: 'supplier_code', case: 'normal' },
    { name: 'Booking Ref.', key: 'booking_reference', case: 'normal' },
    { name: 'PO', key: 'PO', case: 'normal' },
    { name: 'Style Code', key: 'style_sode', case: 'normal' },
    { name: 'Colour', key: 'colour', case: 'normal' },
    { name: 'Fault Description', key: 'Fault_Description', case: 'normal' },
    { name: 'Units', type: 'number', key: 'Units', case: 'normal' },
    { name: 'Cartons', type: 'number', key: 'Cartons', case: 'normal' },
    { name: 'Paper Tape', key: 'paper_tape', case: 'paper_tape_check' },
    { name: 'Comments', key: 'Comments', case: 'normal' },
    { name: 'Rejected', key: 'Rejected', case: 'normal' },
  ],
  GARMENT: [
    { name: 'Date', key: 'inspection_date', case: 'normal' },
    { name: 'Client PO', key: 'client_po', case: 'normal' },
    // { name: 'Consol', key: 'UDC_id', case: 'normal' },
    { name: 'Consol. Centre', key: 'Consol_Centre', case: 'normal' },
    { name: 'Country Of Origin', key: 'Country_of_Origin', case: 'normal' },
    { name: 'Origin Port', key: 'Origin_Port', case: 'normal' },
    { name: 'Supplier Code', key: 'supplier_code', case: 'normal' },
    { name: 'Supplier Name', key: 'supplier_name', case: 'name' },
    { name: 'Style Code', key: 'style_code', case: 'normal' },
    { name: 'Product Description', key: 'Product_Description', case: 'normal' },
    { name: 'Colour', key: 'colour', case: 'normal' },
    { name: 'Unit Height', key: 'Unit_Height', case: 'normal' },
    { name: 'Unit Length', key: 'Unit_Length', case: 'normal' },
    { name: 'Unit Width', key: 'Unit_Width', case: 'normal' },
    { name: 'Carton Height', key: 'carton_height', case: 'normal' },
    { name: 'Carton Length', key: 'carton_length', case: 'normal' },
    { name: 'Carton Width', key: 'carton_width', case: 'normal' },
    { name: 'Paper Tape', key: 'paper_tape', case: 'paper_tape_check' },
    { name: 'Status', key: 'Status', case: 'normal' },
    { name: 'Unit Volume', key: 'Unit_Volume', case: 'normal' },
    { name: 'Est UPC', key: 'Est_UPC', case: 'normal' },
    { name: 'SBS', key: 'SBS', case: 'normal' },
    { name: 'Difference(%)', key: 'PercDiff', case: 'normal' },
    { name: 'Suggested Issues', key: 'Suggested_Issue', case: 'normal' },
  ],
  ADMIN_PAGE: [
    { name: 'Client Name', key: 'name', case: 'normal' },
    { name: 'Visibility Portal', key: 'clientPage', case: 'link' },
    { name: 'Operations Portal', key: 'userPage', case: 'link' },
    // { name: 'Vendor Page', key: 'venderPage', case: 'link' },
  ]
}

export const DEFAULT_CONSTANTS = {
  SORT_DIRECTION: 1,
  SORT_KEY: 'firstName',
  INSP_TYPE: 'DCInsp'
}

export const API_KEYS = {
  SORT_DIRECTION: 'sortOrder',
  SORT_KEY: 'sortKey',
  FILTER_KEY: 'filterKey',
  FILTER_VALUE: 'filterValue',
  SEARCH: 'search',
}

export const MESSAGES = {
  ERRORS: {
    INVALID_INSPECTION_TYPE:'Invalid Inspection type',
    RequireValidation: 'This field is required.',
    MinLengthBillBoardValidation: 'At least 10 characters are required.',
    BlockUser: "Are you sure you want to block this user?",
    DeleteUser: "Are you sure you want to delete this user?",
    ResetPoints: "Are you sure you want to reset this user?",
    ResetGold: "Are you sure to reset the gold of this user?",
    UnblockUser: "Are you sure you want to unblock this user?",
    RestoreUser: "Are you sure you want to restore this user?",
    UnauthorizedUser: "Unauthorized User",
    FailedToAddStyle: "This style cannot be added.",
    FailedToAddUser: "User cannot be added.",
    YourAccountNotApproved: 'Your access is awaiting approval.',
    CompletedSizeChartNotFound: 'Completed size chart cannot be found.',
    FailedToLoadReport: 'Failed to Load Report.',
    FailedToUploadFile: 'File upload Failed!',
    FailedToLoadInspectionReportVersion: 'File to Load Inspection Report Versions!',
    FailedToFetchLocations: 'Failed to fetch locations.',
    FailedToFetchRoles: 'Failed to fetch roles.',
    FailedToAddInspections: 'failed to add Inspections.',
    FailedToUpdateDeliveryQuantity: 'Failed to update delivery quantity.',
    FailedToUpdateSubmissionNumber:'Failed to update Submission Number.',
    INTERNAL_SERVER_ERROR: 'Internal server error.',
    NoChangeDetected:'No changes detected.',
    FailedToUpdateInspection:'Failed to update Inspection!'
  },
  SUCCESS: {
    Unblocked: "User unblocked successfully.",
    StyleAdded: 'Style added successfully.',
    UserAddedSuccessfully: 'User added successfully.',
    POQtyUpdatedSuccesfully: 'PO quantity updated successfully.',
    ResultUpdatedSuccesfully: 'Result updated Successfully.',
    FileUploadedSuccess: 'File uploaded successfully.',
    UserPlatformUpdated: 'User platform updated successfully.',
    UserRoleUpdated: 'User Role updated successfully.',
    UserLocationUpdated: 'User location updated successfully.',
    UserApproved: 'User approved successfully.',
    UserBlocked: 'User blocked successfully.',
    UserDeleted: 'User deleted successfully.',
    RecordUpdated: 'Record updated successfully.',
    TotalUnits: 'Total Units Updated Successfully.',
    InspectionsAdded: 'Inspections added successfully.',
    DeliveryQtyUpdatedSuccessfully: 'Delivery quantity updated successfully.',
    SubmissionNumberUpdatedSuccessfully:'Submission Number updated successfully.',
    SuccessUpdateInspection:'Successfully updated Inspection!'
  },
  TITLES: {
    skippedInspections: 'Skipped Inspections',
    AddStyle: 'Add New Style',
    CreateUser: 'Create User',
    InspectionReports: 'Inspection Reports',
    GarmentDimensions: 'Garment Dimensions',
    CartonCheck: 'Carton Check',
    CartonPhotoThumbnails: 'Carton Photo Thumbnails',
    Accuracy: 'Accuracy',
    Compliance: 'Compliance',
    GarmentCheck: 'Garment Check',
    MaxAllowedDefects: 'Maximum Allowed Defects',
    SampleQtyMaxAllowableDefects: 'Sample Quantity - Maximum Allowable Defects',
    DefectsFound: 'Defects Found',
    PhotoThumbnails: 'Photo Thumbnails',
    FullProductImage: 'Full Product Image',
    Upload: 'Upload'

  },
  LABELS: {
    StyleCode: 'Style Code',
    ProductCode: 'Product Code',
    ProductType: 'Product Type',
    UserName: 'User Name',
    Email: 'Email',
    Role: 'Role',
    Platform: 'Platform',
    Location: 'Location',
    ConsolCentre: 'Consol. Centre',
    Division: 'Division',
    Department: 'Department',
    Date: 'Date',
    Result: 'Result',
    Supplier: 'Supplier',
    StyleName: 'Style Name',
    PoNos: "PO/NO's",
    Quantity: 'Quantity',
    Cartons: 'Cartons',
    InspectionDate: 'Inspection Date',
    InspectionType: 'Inspection Type',
    skipReason: 'Skip Reason',
    SupplierName: 'Supplier Name',
    PONumber: 'PO Number',
    Colour: 'Colour',
    Length: 'Length',
    Breadth: 'Breadth',
    Height: 'Height',
    Weight: 'Weight',
    CartonNo: 'Ctn No',
    PLQty: 'P/L Qty',
    LabelQty: 'Label Qty',
    ActualQty: 'Actual Qty',
    CtnHeight: 'Ctn Height',
    CtnLength: 'Ctn Length',
    CtnWidth: 'Ctn Width',
    FaultFound: 'Fault Found',
    Description: 'Description',
    TotalPoQty: 'Total PO Qty.',
    TotalInspQty: 'Total Inspection Qty.',
    OrderSize: 'Order Size',
    Season: 'Season',
    Submission: 'Submission',
    InspLevel: 'Inspection Level',
    SampleQty: 'Sample Qty.',
    Minor: 'Minor',
    Major: 'Major',
    Critical: 'Critical',
    QtyRemoved: 'Quantity removed',
    Inspector: 'Inspector',
    TotalDefects: 'Total Defects',
    Code: 'Code',
    DefectDesc: 'Defect Description',
    ArrivedDate: 'Arrived Date',
    LastUpdated: 'Last updated',
    TotalUnits: 'Total Units',
    PendingDecisions:"Pending Decisions"
  },
  BUTTONS: {
    Add: 'Add',
    Cancel: 'Cancel',
    Update: 'Update',
    Logout: 'Logout',
    ApplyFilters: 'Apply filters',
    ClearFilters: 'Clear filters',
    Download: 'Download',
    SaveUpload: 'Save and Upload',
    DeleteStyleCode: 'Delete Style Code',
    Edit:"Edit"
  }
}

export const INSPECTION_RESULTS = ['Pass', 'Fail', 'Pending', 'Comm. Pass']

export const ROWS_TO_HIDE = {
  catJacket: ["F 01", "G 05", "J 04", "D 01", "E 01", "P 01", "P 03"],
  catGilet: ["F 01", "G 05", "J 04", "C 01", "P 01", "P 03"],
  catPant: ["G 03", "H 03", "K 01", "J 12", "K 04", "L 01", "L 02"],
  catSkirt: ["G 03", "H 03", "G 05", "J 01"],
  catLSTop: ["F 01", "G 05", "J 04", "D 01", "E 01", "A 13", "P 01", "P 03"],
  catSSTop: ["F 01", "G 05", "J 04", "D 05", "A 13", "P 01", "P 03"],
  catVest: ["F 01", "G 05", "J 04", "C 01", "A 13"],
  catDress: ["F 01", "H 03d", "G 05", "J 04", "C 01", "A 13", "E 01"],
  catJumpsuit: ["F 01", "C 01", "G 03", "H 03d", "K 01", "N 28", "G 05", "D 06a", "D 01", "E 01"],
  catBodysuit: ["F 01", "N 26", "N 15", "C 01", "N 10", "A 13", "D 05", "D 01", "E 01"],
  catBraTop: ["F 01", "H 06a", "J 05", "C 01"],
  catUnderwear: ["N 01", "N 03a", "N 15", "N 23", "N 10", "N 23a"],
  catGlove: ["W 01", "W 03", "X 01", "X 12"],
  catBeanie: ["O 02", "O 03"],
  catEmpty: false
};

export const PRODUCT_MAPPING = {
  'B Bags': 'catEmpty',
  'B Hats & Knitted Accs': 'catBeanie',
  'B Jacket': 'catJacket',
  'B Polo': 'catSSTop',
  'B Shirt': 'catLSTop',
  'B Sundries': 'catEmpty',
  'B Sweats': 'catLSTop',
  'B Swimwear': 'catUnderwear',
  'B T-Shirt': 'catSSTop',
  'B Underwear': 'catUnderwear',
  'B Woven Pant': 'catPant',
  'Bags': 'catEmpty',
  'Belts': 'catEmpty',
  'Cosmetics': 'catEmpty',
  'Fragrance': 'catEmpty',
  'G Bags': 'catEmpty',
  'G Hats & Knitted Accs': 'catBeanie',
  'G Jacket': 'catJacket',
  'G Sundries': 'catEmpty',
  'G Sweats': 'catLSTop',
  'G T-Shirt': 'catSSTop',
  'Hats': 'catEmpty',
  'Knitted Accessories': 'catEmpty',
  'M Bags': 'catEmpty',
  'M Boot': 'catEmpty',
  'M Flip Flop': 'catEmpty',
  'M Hats & Knitted Accs': 'catBeanie',
  'M Jacket': 'catJacket',
  'M Jersey Pant': 'catPant',
  'M Knit': 'catLSTop',
  'M Polo': 'catSSTop',
  'M Shirt': 'catLSTop',
  'M Shoe': 'catEmpty',
  'M Short': 'catPant',
  'M Sleepwear': 'catEmpty',
  'M Snow': 'catEmpty',
  'M Sport': 'catLSTop',
  'M Sport Footwear': 'catEmpty',
  'M Sundries': 'catEmpty',
  'M Sweats': 'catLSTop',
  'M Swimwear': 'catUnderwear',
  'M Tops': 'catLSTop',
  'M T-Shirt': 'catSSTop',
  'M Underwear': 'catUnderwear',
  'M Woven Pant': 'catPant',
  'Non Merchandise': 'catEmpty',
  'Other': 'catEmpty',
  'Snow Accessories': 'catEmpty',
  'Sports Accessories': 'catEmpty',
  'Sundries': 'catEmpty',
  'Sunglasses': 'catEmpty',
  'Third Party': 'catEmpty',
  'W Bags': 'catEmpty',
  'W Boot': 'catEmpty',
  'W Denim Pant': 'catPant',
  'W Dress & One Piece': 'catDress',
  'W Flip Flop': 'catEmpty',
  'W Hats & Knitted Accs': 'catEmpty',
  'W Jacket': 'catJacket',
  'W Jersey Pant': 'catPant',
  'W Knit': 'catLSTop',
  'W Shirt': 'catLSTop',
  'W Shoe': 'catEmpty',
  'W Short': 'catEmpty',
  'W Skirt': 'catSkirt',
  'W Sleepwear': 'catEmpty',
  'W Snow': 'catEmpty',
  'W Sport': 'catLSTop',
  'W Sport Footwear': 'catEmpty',
  'W Sundries': 'catEmpty',
  'W Sweats': 'catLSTop',
  'W Swimwear': 'catUnderwear',
  'W Tops': 'catLSTop',
  'W T-Shirt': 'catSSTop',
  'W Underwear': 'catUnderwear',
  'Watch': 'catEmpty',
  'WW Tops': 'catLSTop'
}

export const PLATFORMS = [
  { id: 1, name: 'Inspect' },
  { id: 2, name: 'Web' },
  { id: 3, name: 'Both' }
]

export const CLIENT_DECISIONS ={
  UNDER_REVIEW: 7
}
